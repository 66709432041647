<template>
  <div class="HomeBanner-wrapper">
    <img :src="HomeImg" alt="HomeImg" />
    <div class="content">
      <div class="content-title">AI学习开始于此</div>
      <div class="content-note">这里是你实现AI梦想的起点</div>
    </div>
    <!-- <div class="buttons">
      <button class="buttons-study" @click="jumpTo('study')">开始学习</button>
      <button class="buttons-practice" @click="jumpTo('practice')">开始实践</button>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import HomeImg from '@/assets/imgs/home-img.png';
import { useRouter } from 'vue-router';

const router = useRouter();
</script>

<style scoped lang="scss">
.HomeBanner-wrapper {
  padding: 9px 16px;
  text-align: center;
  .content {
    text-align: center;
    padding-top: 23px;
    padding-bottom: 32px;
    &-title {
      color: rgba(16, 16, 16, 1);
      font-size: 28px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
      height: 39px;
      line-height: 39px;
    }
    &-note {
      color: rgba(17, 17, 17, 1);
      font-size: 16px;
      height: 29px;
      line-height: 32px;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    padding-left: 37px;
    padding-right: 37px;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    &-study {
      border: none;
      width: 120px;
      height: 40px;
      background-image: url('@/assets/imgs/button-study.png');
    }
    &-practice {
      border: none;
      width: 120px;
      height: 40px;
      background-image: url('@/assets/imgs/button-practice.png');
    }
  }
}
</style>
