<template>
  <div class="HomeActivity-wrapper">
    <div class="items">
      <div class="item" v-for="event in activityList" :key="event.activity_id" @click="jumpPage(event.activity_link)">
        <img :src="event.activity_logo" class="item-logo" />
        <div class="item-title">{{ event.activity_name }}</div>
        <div class="item-date">{{ event.activity_endtime }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { jumpPage } from '@/utils';
import { ref } from 'vue';

import logo4 from '@/assets/imgs/activity.png';
import logo3 from '@/assets/imgs/agent.png';
import logo2 from '@/assets/imgs/live-room.png';
import logo1 from '@/assets/imgs/study-groups.png';

interface HomeActivityInfo {
  activity_id: number;
  activity_name: string;
  activity_endtime: string;
  activity_logo: string;
  activity_link: string;
}

const activityList = ref<HomeActivityInfo[]>([
  {
    activity_id: 1,
    activity_name: '机器学习',
    activity_endtime: '2024-07-01',
    activity_link: 'https://www.datawhale.cn/activity/6',
    activity_logo: logo1,
  },
  {
    activity_id: 2,
    activity_name: '大模型技术',
    activity_endtime: '2024-07-01',
    activity_link: 'https://www.datawhale.cn/activity/6',
    activity_logo: logo2,
  },
  {
    activity_id: 3,
    activity_name: '大模型应用',
    activity_endtime: '2024-07-01',
    activity_link: 'https://www.datawhale.cn/activity/6',
    activity_logo: logo3,
  },
  {
    activity_id: 4,
    activity_name: 'AI夏令营',
    activity_endtime: '2024-07-01',
    activity_link: 'https://www.datawhale.cn/activity/6',
    activity_logo: logo4,
  },
]);
</script>

<style scoped lang="scss">
.HomeActivity-wrapper {
  padding: 39px 34px 32px 34px;
  .items {
    display: flex;
    justify-content: space-around;
    .item {
      text-align: center;
      padding: 6px;
      &-logo {
        width: 54px;
        height: 54px;
      }
      &-title {
        padding-top: 6px;
        padding-bottom: 6px;
        color: rgba(17, 17, 17, 1);
        font-size: 14px;
        font-family: PingFangSC-medium;
      }
      &-date {
        color: rgba(136, 136, 136, 1);
        font-size: 12px;
      }
    }
  }
}
</style>
